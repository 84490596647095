import { CommonModule, Location } from '@angular/common';

import {
  Component,

} from '@angular/core';
import {
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-404-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './not-found-page.component.html',
  styleUrl: './not-found-page.component.scss',
})
export class NotFoundPageComponent {
  constructor(private location: Location) { }

  returnToPreviousPage() {
    this.location.back();
  }
}

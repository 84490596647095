<main>
  <!-- <header>
    <h1>{{ 'login.login-page.admin-portal' | translate }}</h1>
  </header> -->
  <section>
    @if (resetCode()) {
      <div class="reset-pass-view">
        <div id="logo-finwave">
          <i><img src="assets/images/pde_finwave_logo.svg" alt=""></i>
        </div>
        <div class="message">
          <h1>{{ 'login.login-page.recover-password' | translate }}</h1>
          <span>{{ 'login.login-page.create-new-password' | translate }}</span>
        </div>
        <form [formGroup]="resetPassForm" (ngSubmit)="resetPass()" class="resetForm">
          <div class="newPass">
            <app-input-text
            formControlName="newPass"
            [label]="'login.login-page.new-password' | translate"
            placeholder="· · · · · · · · · · · ·"
            isPassword
            [description]="resetPassForm.get('newPass')?.errors && leavedNewPassInput() ? ('login.login-page.need-meets-requirements' | translate) : ''"
            (blurEvent)="userLeavesNewPassInput()"
            (inputEvent)="errorChangingPass = false"
            [error]="resetPassForm.get('newPass')?.errors && leavedNewPassInput() ? true : false"
          />
          <div class="mandatories">
            <span>
              <i><img [src]="!leavedNewPassInput() ? !resetPassForm.get('newPass')?.errors?.['minlength'] && !resetPassForm.get('newPass')?.errors?.['required'] && resetPassForm.get('newPass')?.touched ? 'assets/icons/check_circle_green.svg' : 'assets/icons/out_highlight_off_gray.svg' : !resetPassForm.get('newPass')?.errors?.['minlength'] && !resetPassForm.get('newPass')?.errors?.['required'] ? 'assets/icons/check_circle_green.svg' : 'assets/icons/circle_cancel_red.svg'" alt=""></i> {{ 'login.login-page.password-rule-1' | translate }}
            </span>
            <span>
              <i><img [src]="!leavedNewPassInput() ? !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneUpperCase'] && resetPassForm.get('newPass')?.touched ? 'assets/icons/check_circle_green.svg' : 'assets/icons/out_highlight_off_gray.svg' : !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneUpperCase'] ? 'assets/icons/check_circle_green.svg' : 'assets/icons/circle_cancel_red.svg'" alt=""></i> {{ 'login.login-page.password-rule-2' | translate }}
            </span>
            <span>
              <i><img [src]="!leavedNewPassInput() ? !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneLowerCase'] && resetPassForm.get('newPass')?.touched ? 'assets/icons/check_circle_green.svg' : 'assets/icons/out_highlight_off_gray.svg' : !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneLowerCase'] ? 'assets/icons/check_circle_green.svg' : 'assets/icons/circle_cancel_red.svg'" alt=""></i> {{ 'login.login-page.password-rule-3' | translate }}
            </span>
            <span>
              <i><img [src]="!leavedNewPassInput() ? !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneNumber'] && resetPassForm.get('newPass')?.touched ? 'assets/icons/check_circle_green.svg' : 'assets/icons/out_highlight_off_gray.svg' : !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneNumber'] ? 'assets/icons/check_circle_green.svg' : 'assets/icons/circle_cancel_red.svg'" alt=""></i> {{ 'login.login-page.password-rule-4' | translate }}
            </span>
            <span>
              <i><img [src]="!leavedNewPassInput() ? !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneSpecialCharacter'] && resetPassForm.get('newPass')?.touched ? 'assets/icons/check_circle_green.svg' : 'assets/icons/out_highlight_off_gray.svg' : !resetPassForm.get('newPass')?.errors?.['hasAtLeastOneSpecialCharacter'] ? 'assets/icons/check_circle_green.svg' : 'assets/icons/circle_cancel_red.svg'" alt=""></i> {{ 'login.login-page.password-rule-5' | translate }}
            </span>
          </div>
          </div>

          <app-input-text
            formControlName="confirmPass"
            [label]="'login.login-page.confirm-password' | translate"
            placeholder="· · · · · · · · · · · ·"
            isPassword
            [error]="passDontMatch && resetPassForm.getError('passwordMismatch') ? true : false"
            [description]="passDontMatch && resetPassForm.getError('passwordMismatch') ? ('login.login-page.pass-dont-match' | translate) : ''"
            (inputEvent)="errorChangingPass = false"
          />
          <button
            type="submit"
            class="main_button"
            [class.disabled]="resetPassForm.get('newPass')?.errors || resetPassForm.get('confirmPass')?.errors"
            [disabled]="resetPassForm.get('newPass')?.errors || resetPassForm.get('confirmPass')?.errors"
          >
            {{ 'login.login-page.save-new-password' | translate }}
          </button>
          @if (errorChangingPass) {
            <span style="color: red; display: block; text-align: center">Ha habido un error con el cambio de contraseña</span>
          }
        </form>
      </div>
    } @else {
      @if(currentView === EnumViews.LOGIN) {
        <div id="login-view" class="login-view">
          <div id="logo-finwave">
            <i><img src="assets/images/pde_finwave_logo.svg" alt=""></i>
          </div>
          <div id="app-name">
            <h1>{{ 'admin.app.name' | translate }}</h1>
            <span>{{ 'admin.app.description' | translate }}</span>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="logIn()" class="loginForm">
          <app-input-text
            formControlName="username"
            [label]="'login.login-page.username.title' | translate"
            [placeholder]="'login.login-page.username-placeholder' | translate"
            [description]="'login.login-page.username.description' | translate"
          />
          <app-input-text
            formControlName="password"
            [label]="'login.login-page.password.title' | translate"
            [description]="(invalidCredentials ? 'login.login-page.password.error' : 'login.login-page.password.description') | translate"
            [error]="invalidCredentials ? true : false"
            placeholder="············"
            isPassword
          />
            <button
              type="submit"
              class="main_button mt-5"
              [class.disabled]="loginForm.invalid"
              [disabled]="loginForm.invalid"
            >
              {{ 'login.login-page.enter' | translate }}
            </button>
          </form>

          <div id="buttons">
            <button class="btn-forgot-password" (click)="currentView = EnumViews.RECOVERY">
              <span>{{ 'login.login-page.forgot-password' | translate }}</span>
            </button>
          </div>
        </div>
      } @else if (currentView === EnumViews.RECOVERY) {
        <div id="requestCode-view" class="requestCode-view">
          <button type="button" class="back_button" (click)="currentView = EnumViews.LOGIN">
            <img src="assets/icons/to-back-blue.svg" alt="">
            {{'login.login-page.back' | translate}}
          </button>
          <div id="logo-finwave">
            <i><img src="assets/images/pde_finwave_logo.svg" alt=""></i>
          </div>
          <div class="message">
            <h1>{{ 'login.login-page.recover-password' | translate }}</h1>
            <span>{{ 'login.login-page.enter-email-long' | translate }}</span>
          </div>
          <form [formGroup]="requestCodeForm" (ngSubmit)="requestCode()">
            <app-input-text
              formControlName="email"
              [label]="'login.login-page.username.title' | translate"
              [placeholder]="'login.login-page.recovery-placeholder' | translate"
              [description]="'login.login-page.username.description' | translate"
            ></app-input-text>
            <button type="submit" class="main_button mt-5" [class.disabled]="requestCodeForm.invalid"
            [disabled]="requestCodeForm.invalid">{{ 'login.login-page.send-email' | translate }}</button>
          </form>
          </div>
      } @else {
        <div class="done-view">
          <button type="button" class="back_button" (click)="currentView = EnumViews.LOGIN">
            <img src="assets/icons/to-back-blue.svg" alt="">
            {{'login.login-page.back' | translate}}
          </button>
          <div id="logo-finwave">
            <i><img src="assets/images/pde_finwave_logo.svg" alt=""></i>
          </div>
          <div class="message">
            <i>
              <img src="assets/icons/check_circle_green.svg" alt="">
            </i>
            <h1>{{ 'login.login-page.email-sent' | translate }}</h1>
            <span>{{ 'login.login-page.email-sent-instructions' | translate }} <b>{{requestCodeForm.get('email')?.value}}</b></span>
          </div>
        </div>
      }
    }
  </section>
  <footer>
   <!--  <a href="">{{ 'login.login-page.terms-and-conditions' | translate }}</a>
    <span> - </span>
    <a href="">{{ 'login.login-page.privacy-policy' | translate }}</a> -->
  </footer>
</main>

<!-- Modal para recuperar contraseña olvidada-->
<!-- <app-modal #requestModal id="requestModal">
  <form [formGroup]="requestCodeForm" (ngSubmit)="requestCode(); requestModal.closeDialog()">
    <div class="content">
      <h2>{{ 'login.login-page.recover-password' | translate }}</h2>
      <div class="separator"></div>
      <span>{{ 'login.login-page.recover-password-instructions' | translate }}</span>
      <app-input-text [placeholder]="'login.login-page.email-placeholder' | translate" formControlName="email"/>
    </div>
    <button type="submit" class="main_button no-top-rounded" [class.disabled]="requestCodeForm.invalid"
    [disabled]="requestCodeForm.invalid">{{ 'login.login-page.send' | translate }}</button>
  </form>
</app-modal> -->


<!-- Modal para establecer una nueva contraseña. No se utiliza y en el futuro seguro se elimina-->
<!-- <app-modal #resetModal id="resetModal">
  <form [formGroup]="resetPassForm" (ngSubmit)="resetPass(); resetModal.closeDialog()">
    <div class="content">
      <h2>{{ 'login.login-page.change-password' | translate }}</h2>
      <div class="separator"></div>
      <span>{{ 'login.login-page.new-password-instructions' | translate }}</span>
      <ul>
        <li>{{ 'login.login-page.password-rule-1' | translate }}</li>
        <li>{{ 'login.login-page.password-rule-2' | translate }}</li>
        <li>{{ 'login.login-page.password-rule-3' | translate }}</li>
        <li>{{ 'login.login-page.password-rule-4' | translate }}</li>
      </ul>
      <app-input-text placeholder="******" formControlName="newPass" [label]="'login.login-page.new-password' | translate" isPassword/>
      <app-input-text placeholder="******" formControlName="confirmPass" [label]="'login.login-page.confirm-password' | translate" isPassword/>
    </div>
    <button type="submit" class="main_button no-top-rounded" [class.disabled]="resetPassForm.invalid"
      [disabled]="resetPassForm.invalid">{{ 'login.login-page.send' | translate }}</button>
  </form>
</app-modal> -->

<main>
  <section>
        <div id="not-found-page-view" class="not-found-page">
          <div id="logo-finwave">
            <i><img src="assets/images/pde_finwave_logo.svg" alt=""></i>
          </div>
          <div id="not-found-page-view-container">
            <div>
              <i>
               <img src="assets/icons/pde_icn_mood_sad_red.svg"  alt=""/>
              </i>
            </div>
            <h1>{{ 'ui.messages.error.title' | translate }}</h1>
            <span>{{ 'ui.messages.error.subtitle' | translate }}</span>
             <button
              class="main_button mt-5"
              (click)="returnToPreviousPage()"
              >
              {{ 'ui.buttons.returnPreviousPage' | translate }}
            </button>
          </div>
        </div>
  </section>
</main>
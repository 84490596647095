import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {

  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const newPass = control.get('newPass')?.value;
    const confirmPass = control.get('confirmPass')?.value;

    return newPass === confirmPass ? null : { passwordMismatch: true };
  }

  static hasAtLeastOneUpperCase(
    control: AbstractControl
  ): ValidationErrors | null {
    const value: string = control.value || '';

    const hasAtLeastOneUpperCase = /[A-Z]/.test(value);

    if (hasAtLeastOneUpperCase) {
      return null;
    } else {
      return {
        hasAtLeastOneUpperCase: {
          hasAtLeastOneUpperCase,
        }
      };
    }
  }

  static hasAtLeastOneLowerCase(control: AbstractControl) : ValidationErrors | null {
    const value: string = control.value || '';

      const hasAtLeastOneLowerCase = /[a-z]/.test(value);

      if (hasAtLeastOneLowerCase) {
        return null;
      } else {
        return {
          hasAtLeastOneLowerCase: {
            hasAtLeastOneLowerCase
          }
        };
      }
  }

  static hasAtLeastOneNumber(control: AbstractControl) : ValidationErrors | null {
    const value: string = control.value || '';

      const hasAtLeastOneNumber = /[0-9]/.test(value);

      if (hasAtLeastOneNumber) {
        return null;
      } else {
        return {
          hasAtLeastOneNumber: {
            hasAtLeastOneNumber
          }
        };
      }
  }

  static hasAtLeastOneSpecialCharacter(control: AbstractControl) : ValidationErrors | null {
    const value: string = control.value || '';

      const hasAtLeastOneSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

      if (hasAtLeastOneSpecialCharacter) {
        return null;
      } else {
        return {
          hasAtLeastOneSpecialCharacter: {
            hasAtLeastOneSpecialCharacter
          }
        };
      }
  }
}

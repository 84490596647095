import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login-page.component';
import { isLoggedInGuard } from '@core/guards/is-logged-in.guard';
import { NotFoundPageComponent } from './pages/404/not-found-page.component';
import { verifyResetTokenResolver } from '@core/resolvers/verify-reset-token.resolver';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/admin/admin.routes').then(m => m.ADMIN_ROUTES),
    canActivate: [isLoggedInGuard],
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [isLoggedInGuard],
  },
  {
    path: 'login/:resetCode',
    component: LoginPageComponent,
    canActivate: [isLoggedInGuard],
    resolve: { codeIsValid: verifyResetTokenResolver },
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  }
];

import { CommonModule } from '@angular/common';
import {
  Component,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ILoginForm } from '@core/models/auth/ILoginForm.interface';
import { IRequestCodeForm } from '@core/models/auth/IRequestCodeForm.interface';
import { IResetPassForm } from '@core/models/auth/IResetPassForm.interface';
import { LoginService } from '@core/services/auth/login.service';
import { ResetPassService } from '@core/services/auth/reset-pass.service';
import { InputTextComponent } from '@shared/components/input-text/input-text.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { catchError, map, throwError } from 'rxjs';
import { CustomValidators } from '@shared/helpers/customValidators';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EnumViews } from '@core/models/enum/EnumViews';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextComponent,
    ModalComponent,
    TranslateModule,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  //TODO: CUANDO GOOGLE AUTOCOMPLETA LOS INPUTS DEL FORMULARIO LOGIN, SE SUPERPONEN LA CONTRASEÑA INVISIBLE CON LA VISBILE, ARREGLAR!

  public resetCode = input<string | null>(null);

  public codeIsValid = input<boolean | null>(null);

  public currentView: EnumViews = EnumViews.LOGIN;
  public EnumViews = EnumViews


  // @ViewChild('resetModal') resetModal!: ModalComponent;

  private loginService = inject(LoginService);
  private resetPassService = inject(ResetPassService);
  private router = inject(Router);

  public loginView = true
  public invalidCredentials = false;

  public leavedNewPassInput = signal<boolean>(false);

  public userLeavesNewPassInput() {
    this.leavedNewPassInput.set(true);
  }

  public passDontMatch = false;
  public errorChangingPass = false;

  public loginForm = new FormGroup<ILoginForm>({
    username: new FormControl<string>('', {
      validators: [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)],
    }),
    password: new FormControl<string>('', {
      validators: [Validators.required],
    }),
  });


  public requestCodeForm = new FormGroup<IRequestCodeForm>({
    email: new FormControl('', {
      validators: [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)],
    }),
  });

  public resetPassForm = new FormGroup<IResetPassForm>(
    {
      newPass: new FormControl<string | null>(null, {
        validators: [
          Validators.required,
          Validators.minLength(12),
          CustomValidators.hasAtLeastOneUpperCase,
          CustomValidators.hasAtLeastOneLowerCase,
          CustomValidators.hasAtLeastOneNumber,
          CustomValidators.hasAtLeastOneSpecialCharacter,
        ],
      }),
      confirmPass: new FormControl<string | null>(null, {
        validators: [Validators.required],
      }),
    },
    { validators: CustomValidators.passwordMatchValidator }
  );

  constructor() {
    effect(() => {
      const valid = this.codeIsValid();

      if (!valid) {
        this.router.navigate(['/login']);
      }
    });
  }

  // ngAfterViewInit() {
  //   if (this.resetCode()) {
  //     this.resetModal.openDialog();
  //   }
  // }

  async logIn() {
    this.invalidCredentials = false;

    this.loginService
      .getTokens({
        username: this.loginForm.value.username!,
        password: this.loginForm.value.password!,
      })
      .pipe(
        map((response) => {
          this.loginService.setUserInfo(response.token, response.refresh_token);
          this.router.navigate(['/dashboard']);
        }),
        catchError((error) => {
          this.invalidCredentials = true;
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  //Funcion para enviar email cuando credenciales no son validas
  async requestCode() {
    this.resetPassService
      .requestResetCode(this.requestCodeForm.value.email!)
      .pipe(
        map((response) => {console.log(response.status); this.currentView = EnumViews.DONE}),
        catchError((error) => throwError(() => error))
      )
      .subscribe();
  }

  async resetPass() {
    if (this.resetPassForm.invalid) {
      this.passDontMatch = true;
      return;
    }
    this.resetPassService
      .resetPassword(this.resetCode()!, this.resetPassForm.value.newPass!)
      .pipe(
        map(() => {
          this.passDontMatch = false;
          this.errorChangingPass = false;
          this.router.navigate(['/login']);
        }),
        catchError((error) => {
          this.errorChangingPass = true;
          return throwError(() => error)})
      )
      .subscribe();
  }
}

// async logIn() {
//   this.loginStore.login({
//     username: this.loginForm.value.username!,
//     password: this.loginForm.value.password!,
//   });
// }
